<template>
  <div class='border-t bg-white flex flex-col py-12'>
    <div class='layout-container w-full bg-white flex flex-col lg:flex-row gap-y-8 justify-between px-4 lg:px-0 text-xs lg:text-sm text-gray-700'>
      <div class='w-full lg:w-1/3'>
        <div class='font-semibold text-sm lg:text-base text-gray-900'>The Korean Association of Immunologists</div>
        <div class='mt-2 text-sm'>
          <span class='uppercase inline-block opacity-70 w-full lg:w-36'>Contact Number</span>
          82-2-797-0975
        </div>
        <div class='mt-2 text-sm'>
          <span class='uppercase inline-block opacity-70 w-full lg:w-36'>Email</span> 
          kai1974info@gmail.com
        </div>
        <div class='mt-2 text-sm'>
          <span class='uppercase inline-block opacity-70 w-full lg:w-36'>Fax</span> 
          82-2-797-0976
        </div>
        <div class='mt-2 text-sm'>
          <span class='uppercase inline-block opacity-70 w-full lg:w-36'>Address</span> 
          Rm. 1103, The Korea Science & Technology  Center, 7 Gil 22, Teheran-ro, Gangnam-gu, Seoul 06130, Korea
        </div>
        <div class='mt-2 text-sm'>
          <span class='uppercase inline-block opacity-70 w-full lg:w-36'>Homepage</span> 
          <a href='https://www.ksimm.or.kr' class='underline cursor-pointer' target='_blank'>www.ksimm.or.kr</a>, <a href='https://www.immunenetwork.org' class='underline cursor-pointer' target='_blank'>www.immunenetwork.org</a>
        </div>
      </div>
      <div class='w-full lg:w-1/3'>
        <div class='font-semibold text-sm lg:text-base text-gray-900'>KAI2022 Congress Secretariat</div>
        <div class='mt-2 text-sm'>
          <span class='uppercase inline-block opacity-70 w-full lg:w-36'>Contact Number</span>
          02-6959-5333
        </div>
        <div class='mt-2 text-sm'>
          <span class='uppercase inline-block opacity-70 w-full lg:w-36'>Email</span> 
          with@thewithin.co.kr
        </div>
        <div class='mt-2 text-sm'>
          <span class='uppercase inline-block opacity-70 w-full lg:w-36'>Fax</span> 
          070-8677-6333
        </div>
        <div class='mt-2 text-sm'>
          <span class='uppercase inline-block opacity-70 w-full lg:w-36'>Address</span> 
          1005, Woorin eBIZ Center, #43, Yangsan-ro, Yeongdeungpo-gu, Seoul 07270, Korea
        </div>
      </div>
      <div>
        <img :src='eventConfigLogoImageUrl'
          class='h-14 lg:h-16 cursor-pointer mb-6'
          @click='goToHome'/>
        <div class='flex flex-row gap-x-8 mb-6'>
        <img src='https://d3r2ol85dktaw0.cloudfront.net/conferences/kai2022/logos/incheon_metropolitan_city_logo.jpeg'
          class='h-6' />
        <img src='https://d3r2ol85dktaw0.cloudfront.net/conferences/kai2022/logos/ifez_logo.jpg'
          class='h-6' />
        </div>
        <div
          v-if='hasValidToken' 
          class='w-40 text-left uppercase border px-4 py-2 text-center rounded-md hover:shadow-md cursor-pointer'
          @click='logoutApp'>
          logout
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'KaiSpring2022LayoutFooter',
  computed: {
    ...mapState('users', [
      'hasValidToken',
    ]),
    ...mapGetters('events', [
      'eventConfigLogoImageUrl',
    ]),
  },
  methods: {
    ...mapActions('users', [
      'logout',
      'checkTokenStatus',
    ]),
    goToHome () {
      this.$router.push({name: 'Home'}).catch(() => {})
    },
    logoutApp () {
      this.logout().then(() => {
        this.$router.push({name: 'Login'})
      })
    },
  },
  created () {
    this.checkTokenStatus()
  },
}
</script>
